import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { DialogManager } from "@pp/lib/ui/app";
import {
  Loader,
  HStack,
  VStack,
  Text,
  Button,
  NoData,
  Table,
  Link,
  Breadcrumbs,
  ConfirmationDialog,
} from "@pp/lib/ui/components";
import { sortByField, removeItem } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";
import { AssociateVenueDialog } from "@pp/ui/app/dialogs";

const UserDetails = () => {
  const params = useParams();
  const userId = params.userId;
  const [user, setUser] = React.useState(null);

  if (userId) {
    useAPI(api.userById({ id: userId }), setUser);
  } else {
    return <NoData />;
  }

  return (
    <Loader
      data={user}
      css={{ p: "$6" }}
      content={() => {
        return (
          <VStack css={{ gap: "$4" }}>
            <HStack
              css={{
                p: "$2",
                width: "100%",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Text.H4>
                <Breadcrumbs
                  items={[
                    { label: "Users", to: "/users" },
                    { label: user.name },
                  ]}
                />
              </Text.H4>
              <Button css={{ justifySelf: "end" }}>Edit User</Button>
            </HStack>
          </VStack>
        );
      }}
    />
  );
};

export default UserDetails;
