import React from "react";
import {
  Dialog,
  Button,
  Loader,
  VStack,
  SelectNative,
} from "@pp/lib/ui/components";
import { useAPI } from "@pp/lib/net/api-service";

const AssociateDialogBase = ({
  title,
  message,
  existingItems,
  itemsAPICall,
  onSubmit,
  onClose,
  buttonLabel = "Associate",
}) => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState(null);
  const [itemId, setItemId] = React.useState(null);

  useAPI(itemsAPICall, (result) => {
    setLoading(false);

    if (existingItems) {
      result = result.filter((venue) => {
        return !existingItems.some((existing) => {
          return existing.id === venue.id;
        });
      });
    }

    setItems(result);
    if (result.length > 0) {
      setItemId(result[0].id);
    }
  });

  return (
    <Dialog title={title} open={true}>
      <Loader
        css={{ p: "$4" }}
        data={!loading}
        content={() => {
          return (
            <VStack css={{ gap: "$4" }}>
              {message}
              <SelectNative
                items={items.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                onChange={(value) => {
                  setItemId(value);
                }}
              />
              <Dialog.Buttons>
                <Button onClick={onClose}>Close</Button>
                <Button
                  disabled={itemId === null}
                  onClick={async () => {
                    setLoading(true);
                    await onSubmit(itemId);
                    onClose();
                  }}
                >
                  {buttonLabel}
                </Button>
              </Dialog.Buttons>
            </VStack>
          );
        }}
      />
    </Dialog>
  );
};

export default AssociateDialogBase;
