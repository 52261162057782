import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { DialogManager } from "@pp/lib/ui/app";
import {
  Loader,
  HStack,
  VStack,
  Table,
  Text,
  Button,
  NoData,
  Link,
  Breadcrumbs,
  ConfirmationDialog,
} from "@pp/lib/ui/components";
import { sortByField, removeItem } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";
import { AssociateVenueDialog } from "@pp/ui/app/dialogs";
import AssociateUserDialog from "@pp/ui/app/accounts/AssociateUserDialog";

const AccountDetails = () => {
  const params = useParams();
  const accountId = params.accountId;
  const [account, setAccount] = React.useState(null);

  if (accountId) {
    // useAPI(api.accountById({ id: accountId }), setAccount);
  } else {
    return <NoData />;
  }

  return (
    <Loader
      data={account}
      css={{ p: "$6" }}
      content={() => {
        return (
          <VStack css={{ gap: "$4" }}>
            <HStack
              css={{
                p: "$2",
                width: "100%",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Text.H4>
                <Breadcrumbs
                  items={[
                    { label: "Accounts", to: "/accounts" },
                    { label: account.name },
                  ]}
                />
              </Text.H4>

              <Button css={{ justifySelf: "end" }}>Edit Account</Button>
            </HStack>
            <VenueSection account={account} />
            <UsersSection account={account} />
          </VStack>
        );
      }}
    />
  );
};

const VenueSection = ({ account }) => {
  const { showDialog } = DialogManager.useDialogs();
  const [venues, setVenues] = React.useState([...account.venues]);

  return (
    <Section
      title="Venues"
      actions={
        <Button
          onClick={() => {
            showDialog(AssociateVenueDialog, {
              message: `Select a venue to associate with the account ${account.name}`,
              existingVenues: account.venues,
              onSubmit: async (venueId) => {
                // const result = await api.associateVenueWithAccount({
                //   accountId: account.id,
                //   venueId,
                // });
                // account.venues.push(result);
                // setVenues([...account.venues]);
              },
            });
          }}
        >
          Associate Venue
        </Button>
      }
    >
      <Table.Simple
        items={venues}
        headers={[
          { label: "Id", sortMethod: sortByField("id") },
          { label: "Name", sortMethod: sortByField("name") },
          { label: "Survey Id", sortMethod: sortByField("surveyId") },
          { label: "Status", sortMethod: sortByField("status") },
          { label: "", sortMethod: null },
        ]}
        itemKey={(item) => {
          return item.id;
        }}
        itemRenderer={(item) => {
          return [
            <Link to={`/venues/${item.id}`}>{item.id}</Link>,
            item.name,
            item.surveyId,
            item.status,
            <HStack css={{ gap: "$2", justifyContent: "end" }}>
              <Button
                onClick={() => {
                  showDialog(ConfirmationDialog, {
                    title: "Remove Venue",
                    message:
                      "Are you sure you want to remove this venue from the account?",
                    confirmButtonText: "Remove",
                    onConfirm: async () => {
                      // await api.removeVenueFromAccount({
                      //   accountId: account.id,
                      //   venueId: item.id,
                      // });
                      // removeItem(account.venues, item);
                    },
                  });
                }}
              >
                Remove
              </Button>
              {item.status === "Active" ? (
                <Button>Disable</Button>
              ) : (
                <Button>Activate</Button>
              )}
            </HStack>,
          ];
        }}
      />
    </Section>
  );
};

const UsersSection = ({ account }) => {
  const { showDialog } = DialogManager.useDialogs();
  const [users, setUsers] = React.useState([...account.users]);

  return (
    <Section
      title="Users"
      actions={
        <Button
          onClick={() => {
            showDialog(AssociateUserDialog, {
              existingUsers: users,
              onSubmit: async (userId) => {
                // const result = await api.addUserToAccount({
                //   accountId: account.id,
                //   userId,
                // });
                // account.users.push(result);
                // setUsers([...account.users]);
              },
            });
          }}
        >
          Add User
        </Button>
      }
    >
      <Table.Simple
        items={users}
        headers={[
          { label: "Id", sortMethod: sortByField("id") },
          { label: "Name", sortMethod: sortByField("name") },
          { label: "Status", sortMethod: sortByField("status") },
          { label: "", sortMethod: null },
        ]}
        itemKey={(item) => {
          return item.id;
        }}
        itemRenderer={(item) => {
          return [
            <Link to={`/users/${item.id}`}>{item.id}</Link>,
            item.name,
            item.status,
            <HStack css={{ gap: "$2", justifyContent: "end" }}>
              <Button>Remove</Button>
              {item.status === "Active" ? (
                <Button>Disable</Button>
              ) : (
                <Button>Activate</Button>
              )}
            </HStack>,
          ];
        }}
      />
    </Section>
  );
};

export default AccountDetails;
