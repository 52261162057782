import React from "react";
import { useAPI } from "@pp/lib/net/api-service";
import { Loader, Table, NoData, Link } from "@pp/lib/ui/components";
import { sortByField } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";

const ListPage = ({
  title,
  actions,
  path,
  apiCall,
  preloadedData = null,
  additionalFields = [],
}: {
  title: string;
  actions?: any;
  path: string;
  apiCall?: Promise<any>;
  preloadedData?: any;
  additionalFields?: Array<any>;
}) => {
  const [data, setData] = React.useState(preloadedData);
  if (apiCall) {
    useAPI(apiCall, setData);
  }

  return (
    <Section title={title} actions={actions}>
      <Loader
        data={data}
        css={{ p: "$6" }}
        content={() => {
          if (data.length === 0) {
            return <NoData />;
          }
          return (
            <Table.Simple
              items={data}
              headers={[
                { label: "Id", sortMethod: sortByField("id") },
                { label: "Name", sortMethod: sortByField("name") },
                ...additionalFields,
              ]}
              itemKey={(item) => {
                return item.id;
              }}
              itemRenderer={(item) => {
                return [
                  <Link to={`/${path}/${item.id}`}>{item.id}</Link>,
                  item.name,
                  ...additionalFields.map((field) => field.itemRender(item)),
                ];
              }}
            />
          );
        }}
      />
    </Section>
  );
};

export default ListPage;
