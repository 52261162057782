import React from "react";
import api from "@pp/lib/net/api-service";
import { AssociateDialogBase } from "@pp/ui/app/dialogs";

const AssociateBenchmarkDialog = ({
  message,
  existingVenues,
  onSubmit,
  onClose,
}) => {
  return (
    <AssociateDialogBase
      title="Associate Venue"
      message={message}
      existingItems={existingVenues}
      itemsAPICall={api.venues()}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default AssociateBenchmarkDialog;
