import React from "react";
import api from "@pp/lib/net/api-service";
import { Button } from "@pp/lib/ui/components";
import ListPage from "@pp/ui/app/ListPage";

const Venues = () => {
  return (
    <ListPage
      title="Venues"
      actions={<Button>Create Venue</Button>}
      path="venues"
      apiCall={api.venues()}
    />
  );
};

export default Venues;