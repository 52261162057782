import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { DialogManager } from "@pp/lib/ui/app";
import {
  Loader,
  HStack,
  VStack,
  Text,
  Button,
  NoData,
  Table,
  Link,
  Breadcrumbs,
  ConfirmationDialog,
} from "@pp/lib/ui/components";
import { sortByField, removeItem } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";
import { AssociateVenueDialog } from "@pp/ui/app/dialogs";

const FieldMappingDetails = () => {
  const params = useParams();
  const fieldMappingId = params.fieldMappingId;
  const [fieldMapping, setFieldMapping] = React.useState(null);

  if (fieldMappingId) {
    // useAPI(api.fieldMappingById({ id: fieldMappingId }), setFieldMapping);
  } else {
    return <NoData />;
  }

  return (
    <Loader
      data={fieldMapping}
      css={{ p: "$6" }}
      content={() => {
        return (
          <VStack css={{ gap: "$4" }}>
            <HStack
              css={{
                p: "$2",
                width: "100%",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Text.H4>
                <Breadcrumbs
                  items={[
                    { label: "Field Mappings", to: "/field-mappings" },
                    { label: fieldMapping.name },
                  ]}
                />
              </Text.H4>
              <Button css={{ justifySelf: "end" }}>Edit Field Mapping</Button>
            </HStack>
          </VStack>
        );
      }}
    />
  );
};

export default FieldMappingDetails;
