import React from "react";
import { HStack, Panel } from "@pp/lib/ui/components";

const Section = ({ title = "", actions = null, children = null }) => {
  return (
    <Panel>
      <Panel.Header>
        <HStack css={{ justifyContent: "space-between", width: "100%" }}>
          {title}
          {actions && <HStack css={{ gap: "$2" }}>{actions}</HStack>}
        </HStack>
      </Panel.Header>
      <Panel.Content
        css={{
          display: "flex",
          height: "calc(100% - $panelHeaderHeight)",
          width: "100%",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Panel.Content>
    </Panel>
  );
};

export default Section;
