import React from "react";

export const AccountDatabaseContext = React.createContext<{
  accountDatabase?;
  setAccountDatabase?;
}>({});

export const TouchpointDefinitionsContext = React.createContext<{
  touchpoints?;
  setTouchpoints?;
}>({});

export const GlobalProvider = ({ children = null }) => {
  const [accountDatabase, setAccountDatabase] = React.useState(null);
  const [touchpointDefinitions, setTouchpointDefinitions] =
    React.useState(null);

  return (
    <AccountDatabaseContext.Provider
      value={{ accountDatabase, setAccountDatabase }}
    >
      <TouchpointDefinitionsContext.Provider
        value={{ touchpointDefinitions, setTouchpointDefinitions }}
      >
        {children}
      </TouchpointDefinitionsContext.Provider>
    </AccountDatabaseContext.Provider>
  );
};
