import React from "react";
import api, { useAPI } from "@pp/lib/net/api-service";
import {
  VStack,
  Text,
  SimpleForm,
  Loader,
  Table,
  Link,
} from "@pp/lib/ui/components";
import Section from "@pp/ui/app/Section";
import { sortByField } from "@pp/lib/utils";

const Benchmarks = () => {
  const [benchmarks, setBenchmarks] = React.useState(null);
  useAPI(api.admin.benchmarks(), (result) => {
    setBenchmarks(result.definitions);
  });

  return (
    <Loader
      data={benchmarks}
      css={{ p: "$6" }}
      content={() => {
        return benchmarks.map((benchmark) => {
          return (
            <React.Fragment key={benchmark.id}>
              <BenchmarkSection benchmark={benchmark} />
              <br />
            </React.Fragment>
          );
        });
      }}
    />
  );
};

const BenchmarkSection = ({ benchmark }) => {
  return (
    <Section title={<Text.H2>{benchmark.label}</Text.H2>}>
      <VStack css={{ gap: "$4", width: "$full" }}>
        <SimpleForm
          fields={[
            { label: "Id", control: <Text>{benchmark.id}</Text> },
            { label: "Name", control: <Text>{benchmark.label}</Text> },
            {
              label: "Contributing Venues",
              control: (
                <Table.Simple
                  items={benchmark.contributingVenues}
                  headers={[
                    {
                      label: "Account Id",
                      sortMethod: sortByField("accountId"),
                    },
                    { label: "Venue Id", sortMethod: sortByField("venueId") },
                  ]}
                  itemKey={(item) => {
                    return `${item.accountId}-${item.venueId}`;
                  }}
                  itemRenderer={(item) => {
                    return [item.accountId, item.venueId];
                  }}
                />
              ),
            },
            {
              label: "Static Influences",
              control: (
                <Table.Simple
                  items={benchmark.staticInfluences}
                  headers={[
                    {
                      label: "Id",
                      sortMethod: sortByField("id"),
                    },
                    { label: "Weight", sortMethod: sortByField("weight") },
                  ]}
                  itemKey={(item) => {
                    return item.id;
                  }}
                  itemRenderer={(item) => {
                    return [
                      <Link to={`/benchmarks/static-scores/${item.id}`}>
                        {item.id}
                      </Link>,

                      item.weight * 100 + "%",
                    ];
                  }}
                />
              ),
            },
          ]}
        />
        <BenchmarkValues benchmark={benchmark} />
      </VStack>
    </Section>
  );
};

const BenchmarkValues = ({ benchmark }) => {
  const [benchmarkValues, setBenchmarkValues] = React.useState(null);

  useAPI(api.admin.benchmarkValues(benchmark.id), (result) => {
    setBenchmarkValues(result);
  });

  return (
    <VStack css={{ gap: "$4", width: "$full" }}>
      <Text.H2>Benchmark Values (based on last month of data)</Text.H2>
      <Loader
        data={benchmarkValues}
        css={{ p: "$6" }}
        content={() => {
          return (
            <>
              <NPSValues values={benchmarkValues.nps} />
              <ScoreValuesWithDistribution
                headerLabel="Touchpoint"
                values={benchmarkValues.touchpoints}
              />
              <ScoreValuesWithDistribution
                headerLabel="Theme"
                values={benchmarkValues.themes}
              />
            </>
          );
        }}
      />
    </VStack>
  );
};

const NPSValues = ({ values }) => {
  const scores = Object.keys(values.scores).map((k) => {
    const score = values.scores[k];
    return { label: k, ...score };
  });

  return (
    <Section title={<Text.H4>NPS Values</Text.H4>}>
      <VStack css={{ flex: 1, gap: "$4" }}>
        <Table.Simple
          items={scores}
          headers={[
            {
              label: "NPS Type",
              sortMethod: sortByField("label"),
            },
            {
              label: "Detractors",
              sortMethod: sortByField("detractors"),
            },
            {
              label: "Promoters",
              sortMethod: sortByField("promoters"),
            },
            {
              label: "Raw Score",
              sortMethod: sortByField("rawScore"),
            },
            {
              label: "Influenced Score",
              sortMethod: sortByField("score"),
            },
          ]}
          itemKey={(item) => {
            return item.label;
          }}
          itemRenderer={(item) => {
            return [
              item.label,
              item.detractors,
              item.promoters,
              item.rawScore,
              item.score,
            ];
          }}
        />
        <Text.H5>Total Responses: {values.totalResponses}</Text.H5>
      </VStack>
    </Section>
  );
};

const ScoreValuesWithDistribution = ({ headerLabel, values }) => {
  const scores = Object.keys(values).map((k) => {
    const score = values[k];
    return { label: k, ...score };
  });

  return (
    <Section title={<Text.H4>{headerLabel} Values</Text.H4>}>
      <Table.Simple
        items={scores}
        headers={[
          {
            label: headerLabel,
            sortMethod: sortByField("label"),
          },
          {
            label: "Count",
            sortMethod: sortByField("count"),
          },
          {
            label: "Distribution",
            sortMethod: () => 0,
          },
          {
            label: "Total",
            sortMethod: sortByField("total"),
          },
          {
            label: "Raw Score",
            sortMethod: sortByField("rawScore"),
          },
          {
            label: "Influenced Score",
            sortMethod: sortByField("score"),
          },
        ]}
        itemKey={(item) => {
          return item.label;
        }}
        itemRenderer={(item) => {
          return [
            item.label,
            item.count,
            Object.keys(item.distribution).map((k) => {
              const val = item.distribution[k];
              return (
                <React.Fragment key={k}>
                  {k}: {val}
                  <br />
                </React.Fragment>
              );
            }),
            item.total,
            item.rawScore,
            item.score,
          ];
        }}
      />
    </Section>
  );
};

export default Benchmarks;
