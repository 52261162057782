import React, { useEffect } from "react";
import {
  Dialog,
  Button,
  Loader,
  VStack,
  SimpleForm,
  SelectNative,
} from "@pp/lib/ui/components";
import api from "@pp/lib/net/api-service";

const EditCommentAnalysisDialog = ({ commentAnalysis, onClose }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Dialog title="Edit CommentAnalysis" open={true}>
      <Loader
        css={{ p: "$4" }}
        data={!loading}
        content={() => {
          return (
            <DialogContent
              commentAnalysis={commentAnalysis}
              setLoading={setLoading}
              onClose={onClose}
            />
          );
        }}
      />
    </Dialog>
  );
};

type Prompt = {
  context: string;
  messages: string[];
};

const DialogContent = ({ commentAnalysis, setLoading, onClose }) => {
  const {
    getFormValues,
    fields,
  }: {
    getFormValues: () => { id: string; name: string; prompts: Prompt[] };
    fields: { label: string; control: React.element };
  } = SimpleForm.helper(commentAnalysis, [
    {
      label: "Name",
      key: "name",
      controlProps: { css: { width: "100%" } },
    },
    {
      label: "Prompts",
      key: "prompts",
      control: PromptEditor,

      controlProps: { promps: commentAnalysis.prompt },
    },
  ]);

  return (
    <VStack css={{ gap: "$4" }}>
      <SimpleForm fields={fields} />
      <Dialog.Buttons>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={async () => {
            // setLoading(true);
            // const values = getFormValues();
            // await api.updateCommentAnalysis({
            //   id: commentAnalysis.id,
            //   ...values,
            // });
            // for (const key in values) {
            //   commentAnalysis[key] = values[key];
            // }
            // onClose();
          }}
        >
          Update Comment Analysis
        </Button>
      </Dialog.Buttons>
    </VStack>
  );
};

const PromptEditor = ({ prompts }) => {
  return <div>Prompt Editor</div>;
};

export default EditCommentAnalysisDialog;
