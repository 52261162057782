import React from "react";
import { VStack, Table, Text, SimpleForm } from "@pp/lib/ui/components";
import { sortByField } from "@pp/lib/utils";

import {
  AccountDatabaseContext,
  TouchpointDefinitionsContext,
} from "@pp/ui/app/GlobalContexts";

import Section from "@pp/ui/app/Section";

const Overview = () => {
  const { accountDatabase } = React.useContext(AccountDatabaseContext);
  return (
    <VStack css={{ gap: "$4" }}>
      {accountDatabase.Accounts.map((account) => {
        return (
          <React.Fragment key={account.Id}>
            <AccountSection account={account} />
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

const AccountSection = ({ account }) => {
  return (
    <Section title={<Text.H1>{account.Name}</Text.H1>}>
      <VStack css={{ gap: "$4", width: "$full" }}>
        <SimpleForm
          fields={[
            { label: "Id", control: <Text>{account.Id}</Text> },
            { label: "Name", control: <Text>{account.Name}</Text> },
            { label: "Short Id", control: <Text>{account.ShortId}</Text> },
          ]}
        />
        <Text.H2>Venues</Text.H2>
        {account.VenueList.map((venue) => {
          return (
            <React.Fragment key={venue.Id}>
              <VenueSection venue={venue} />
            </React.Fragment>
          );
        })}
      </VStack>
    </Section>
  );
};

const VenueSection = ({ venue }) => {
  return (
    <Section title={venue.Name}>
      <SimpleForm
        fields={[
          { label: "Id", control: <Text>{venue.Id}</Text> },
          { label: "Name", control: <Text>{venue.Name}</Text> },
          { label: "Survey Id", control: <Text>{venue.SurveyId}</Text> },
          { label: "Size", control: <Text>{venue.Size}</Text> },
          { label: "State", control: <Text>{venue.State}</Text> },
          { label: "Region", control: <Text>{venue.Region}</Text> },
          { label: "Landlord", control: <Text>{venue.Landlord}</Text> },
          {
            label: "Benchmarks",
            control: (
              <Table.Simple
                items={venue.Benchmarks}
                headers={[
                  { label: "Id", sortMethod: sortByField("Id") },
                  { label: "Label", sortMethod: sortByField("Label") },
                ]}
                itemKey={(item) => {
                  return item.Id;
                }}
                itemRenderer={(item) => {
                  return [item.Id, item.Label];
                }}
              />
            ),
          },
          {
            label: "Comment Analysis",
            control: <Text>{venue.LLMAnalysis.Comments}</Text>,
          },
          {
            label: "NPS Fields",
            control: <FieldsTable fields={venue.FieldMappings.Nps} />,
          },
          {
            label: "Statistic Fields",
            control: (
              <FieldsTableWithValues fields={venue.FieldMappings.Statistics} />
            ),
          },
          {
            label: "Behaviour Fields",
            control: (
              <FieldsTableWithValues fields={venue.FieldMappings.Behaviours} />
            ),
          },
          {
            label: "Suggestions Fields",
            control: <FieldMapper fields={venue.FieldMappings.Suggestions} />,
          },
          {
            label: "Touchpoint Definition",
            control: <Text>{venue.FieldMappings.Touchpoints.Definition}</Text>,
          },
          {
            label: "Touchpoint Configuration",
            control: (
              <TouchpointDetails
                definition={venue.FieldMappings.Touchpoints.Definition}
                venueTouchpoints={venue.FieldMappings.Touchpoints.Values}
              />
            ),
          },
        ]}
      />
    </Section>
  );
};

const FieldMapper = ({ fields }) => {
  return (
    <SimpleForm
      itemKey={(item) => item.id}
      fields={Object.keys(fields).map((key) => {
        let v = fields[key];
        return {
          label: key,
          control: <Text>{v.QuestionId}</Text>,
        };
      })}
    />
  );
};

const FieldsTable = ({ fields }) => {
  const data = Object.keys(fields).map((f) => {
    return { id: f, value: fields[f] };
  });
  return (
    <Table.Simple
      items={data}
      headers={[{ label: "Id" }, { label: "Question Id" }]}
      itemKey={(item) => {
        return item.id;
      }}
      itemRenderer={(item) => {
        return [item.id, item.value.QuestionId];
      }}
    />
  );
};

const FieldsTableWithValues = ({ fields }) => {
  const data = Object.keys(fields).map((f) => {
    return { id: f, value: fields[f] };
  });
  return (
    <Table.Simple
      items={data}
      headers={[{ label: "Id" }, { label: "Question Id" }, { label: "Values" }]}
      itemKey={(item) => {
        return item.id;
      }}
      itemRenderer={(item) => {
        return [
          item.id,
          item.value.QuestionId,
          <SimpleForm
            itemKey={(val) => val.Value}
            fields={item.value.Values.map((val) => {
              return {
                label: val.Value,
                control: <Text>{val.Label}</Text>,
              };
            })}
          />,
        ];
      }}
    />
  );
};

const TouchpointDetails = ({ definition, venueTouchpoints }) => {
  const { touchpointDefinitions } = React.useContext(
    TouchpointDefinitionsContext
  );

  const def = touchpointDefinitions.definitions.find(
    (tp) => tp.id === definition
  );

  if (!def || !def.touchpoints) {
    return <Text css={{ color: "red" }}>(Unknown Touchpoint Definition)</Text>;
  }
  const touchpoints = def.touchpoints;

  return (
    <Table.Simple
      items={touchpoints}
      headers={[
        { label: "Id" },
        { label: "Question Id" },
        { label: "Label" },
        { label: "Deep Dive" },
      ]}
      itemKey={(item) => {
        return item;
      }}
      itemRenderer={(item) => {
        const venueTP = venueTouchpoints[item];
        if (venueTP) {
          const themeQuestions = venueTP.ThemeQuestions;
          return [
            item,
            venueTP.QuestionId,
            venueTP.Label,
            themeQuestions ? (
              <Table.Simple
                items={Object.keys(venueTP.ThemeQuestions)}
                headers={[
                  { label: "Id" },
                  { label: "Question Id" },
                  { label: "Theme" },
                ]}
                itemKey={(key) => {
                  return key;
                }}
                itemRenderer={(key) => {
                  const question = venueTP.ThemeQuestions[key];
                  return [, question.QuestionId, question.Theme];
                }}
              />
            ) : (
              <Missing />
            ),
          ];
        } else {
          return [item, <Missing />, <Missing />, <Missing />];
        }
      }}
    />
  );
};

const Missing = () => {
  return <Text css={{ color: "red" }}>(Missing)</Text>;
};

export default Overview;
