import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { DialogManager } from "@pp/lib/ui/app";
import {
  Loader,
  HStack,
  VStack,
  Text,
  Button,
  NoData,
  Table,
  Link,
  Breadcrumbs,
  ConfirmationDialog,
  SimpleForm,
  List,
} from "@pp/lib/ui/components";
import { sortByField, removeItem } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";
import { AssociateVenueDialog } from "@pp/ui/app/dialogs";
import EditCommentAnalysisDialog from "./EditCommentAnalysisDialog";

const CommentAnalysisDetails = () => {
  const params = useParams();
  const commentAnalysisId = params.commentAnalysisId;
  const [commentAnalysis, setCommentAnalysis] = React.useState(null);

  if (commentAnalysisId) {
    useAPI(
      api.commentAnalysisById({ id: commentAnalysisId }),
      setCommentAnalysis
    );
  } else {
    return <NoData />;
  }

  return (
    <Loader
      data={commentAnalysis}
      css={{ p: "$6" }}
      content={() => {
        return (
          <VStack css={{ gap: "$4" }}>
            <HStack
              css={{
                p: "$2",
                width: "100%",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Text.H4>
                <Breadcrumbs
                  items={[
                    { label: "Comment Analyses", to: "/comment-analysis" },
                    { label: commentAnalysis.name },
                  ]}
                />
              </Text.H4>
            </HStack>
            <CommentAnalysisSection commentAnalysis={commentAnalysis} />
          </VStack>
        );
      }}
    />
  );
};

const CommentAnalysisSection = ({ commentAnalysis }) => {
  const { showDialog } = DialogManager.useDialogs();

  return (
    <Section
      title="Comment Analysis"
      actions={
        <Button
          onClick={() => {
            showDialog(EditCommentAnalysisDialog, { commentAnalysis });
          }}
        >
          Edit
        </Button>
      }
    >
      <SimpleForm
        fields={[
          { label: "Id", control: <Text>{commentAnalysis.id}</Text> },
          {
            label: "Name",
            control: <Text>{commentAnalysis.name}</Text>,
          },
          {
            label: "Prompts",
            control: (
              <Table.Simple
                items={commentAnalysis.prompts}
                headers={[
                  { label: "Context", sortMethod: sortByField("context") },
                  { label: "Messages" },
                ]}
                itemKey={(item) => {
                  return item.context;
                }}
                itemRenderer={(item) => {
                  return [
                    item.context,
                    <List.Unorderd items={item.messages} />,
                  ];
                }}
              />
            ),
          },
        ]}
      />
    </Section>
  );
};

export default CommentAnalysisDetails;
