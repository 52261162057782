import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { Loader, VStack, Text, Table, SimpleForm } from "@pp/lib/ui/components";
import { sortByField } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";

const StaticScores = () => {
  const [staticScore, setStaticScore] = React.useState(null);
  const scoreId = useParams().scoreId;

  useAPI(api.admin.staticScores(), (result) => {
    const score = result.find((score) => score.id === scoreId);
    console.log(score);
    if (score) {
      setStaticScore(score);
    }
  });

  return (
    <Loader
      data={staticScore}
      css={{ p: "$6" }}
      content={() => {
        return <StaticScoresSection staticScore={staticScore} />;
      }}
    />
  );
};

const StaticScoresSection = ({ staticScore }) => {
  const nps = Object.keys(staticScore.nps).map((k) => {
    const score = staticScore.nps[k];
    return { label: k, ...score };
  });

  const touchpoints = Object.keys(staticScore.touchpoints).map((k) => {
    const score = staticScore.touchpoints[k];
    return { label: k, ...score };
  });

  const themes = Object.keys(staticScore.themes.overall).map((k) => {
    const score = staticScore.themes.overall[k];
    return { label: k, ...score };
  });

  return (
    <Section title={<Text.H2>{staticScore.name}</Text.H2>}>
      <VStack css={{ gap: "$4", width: "$full" }}>
        <SimpleForm
          fields={[
            { label: "Id", control: <Text>{staticScore.id}</Text> },
            { label: "Name", control: <Text>{staticScore.name}</Text> },
            {
              label: "NPS",
              control: (
                <Table.Simple
                  items={nps}
                  headers={[
                    {
                      label: "NPS Type",
                      sortMethod: sortByField("label"),
                    },
                    {
                      label: "Detractors",
                      sortMethod: sortByField("detractors"),
                    },
                    {
                      label: "Promoters",
                      sortMethod: sortByField("promoters"),
                    },
                    { label: "Score", sortMethod: sortByField("score") },
                  ]}
                  itemKey={(item) => {
                    return item.label;
                  }}
                  itemRenderer={(item) => {
                    return [
                      item.label,
                      item.detractors,
                      item.promoters,
                      item.score.toFixed(4),
                    ];
                  }}
                />
              ),
            },
            {
              label: "Touchpoints",
              control: (
                <ScoreTable firstHeading="Touchpoint" data={touchpoints} />
              ),
            },
            {
              label: "Themes (Overall)",
              control: <ScoreTable firstHeading="Theme" data={themes} />,
            },
          ]}
        />
      </VStack>
    </Section>
  );
};

const ScoreTable = ({ firstHeading, data }) => {
  return (
    <Table.Simple
      items={data}
      headers={[
        {
          label: firstHeading,
          sortMethod: sortByField("label"),
        },
        {
          label: "Count",
          sortMethod: sortByField("count"),
        },
        {
          label: "Total",
          sortMethod: sortByField("total"),
        },
        { label: "Score", sortMethod: sortByField("score") },
      ]}
      itemKey={(item) => {
        return item.label;
      }}
      itemRenderer={(item) => {
        return [item.label, item.count, item.total, item.score.toFixed(4)];
      }}
    />
  );
};

export default StaticScores;
