import React from "react";
import { useParams } from "react-router-dom";
import api, { useAPI } from "@pp/lib/net/api-service";
import { DialogManager } from "@pp/lib/ui/app";
import {
  Loader,
  HStack,
  VStack,
  Table,
  Text,
  Button,
  NoData,
  Link,
  SimpleForm,
  Breadcrumbs,
} from "@pp/lib/ui/components";
import { sortByField } from "@pp/lib/utils";
import Section from "@pp/ui/app/Section";
import EditVenueDialog from "./EditVenueDialog";
import AssociateBenchmarkDialog from "./AssociateBenchmarkDialog";

const VenueDetails = () => {
  const params = useParams();
  const venueId = params.venueId;
  const [venue, setVenue] = React.useState(null);
  if (venueId) {
    // useAPI(api.venueById({ id: venueId }), setVenue);
  } else {
    return <NoData />;
  }

  return (
    <Loader
      data={venue}
      css={{ p: "$6" }}
      content={() => {
        return (
          <VStack css={{ gap: "$4" }}>
            <Text.H4>
              <Breadcrumbs
                items={[
                  { label: "Venues", to: "/venues" },
                  { label: venue.name },
                ]}
              />
            </Text.H4>
            <DetailsSection venue={venue} />
            <AccountsSection venue={venue} />
            <BenchmarksSection venue={venue} />
          </VStack>
        );
      }}
    />
  );
};

const DetailsSection = ({ venue }) => {
  const { showDialog } = DialogManager.useDialogs();

  return (
    <Section
      title="Venue Details"
      actions={
        <Button
          onClick={() => {
            showDialog(EditVenueDialog, { venue });
          }}
        >
          Edit
        </Button>
      }
    >
      <SimpleForm
        fields={[
          { label: "Id", control: <Text>{venue.id}</Text> },
          { label: "Name", control: <Text>{venue.name}</Text> },
          { label: "Survey Id", control: <Text>{venue.surveyId}</Text> },
          { label: "Status", control: <Text>{venue.status}</Text> },
          {
            label: "Comment Analysis",
            control: (
              <Text>
                <Link to={`/comment-analysis/${venue.commentAnalysis.id}`}>
                  {venue.commentAnalysis.name}
                </Link>
              </Text>
            ),
          },
          {
            label: "Field Mappings",
            control: (
              <Text>
                <Link to={`/field-mappings/${venue.fieldMapping.id}`}>
                  {venue.fieldMapping.name}
                </Link>
              </Text>
            ),
          },
        ]}
      />
    </Section>
  );
};

const AccountsSection = ({ venue }) => {
  return (
    <Section
      title="Associated Accounts"
      actions={<Button>Associate with Account</Button>}
    >
      <Table.Simple
        items={venue.accounts}
        headers={[
          { label: "Id", sortMethod: sortByField("id") },
          { label: "Name", sortMethod: sortByField("name") },
          { label: "", sortMethod: null },
        ]}
        itemKey={(item) => {
          return item.id;
        }}
        itemRenderer={(item) => {
          return [
            <Link to={`/accounts/${item.id}`}>{item.id}</Link>,
            item.name,
            <HStack css={{ gap: "$2", justifyContent: "end" }}>
              <Button>Remove</Button>
            </HStack>,
          ];
        }}
      />
    </Section>
  );
};

const BenchmarksSection = ({ venue }) => {
  const { showDialog } = DialogManager.useDialogs();

  const [benchmarks, setBenchmarks] = React.useState([...venue.benchmarks]);

  return (
    <Section
      title="Benchmarks"
      actions={
        <Button
          onClick={() => {
            showDialog(AssociateBenchmarkDialog, {
              existingBenchmarks: benchmarks,
              onSubmit: async (benchmarkId) => {
                console.log("associate benchmark", benchmarkId);
                // const result = await api.associateBenchmarkWithVenue({
                //   venueId: venue.id,
                //   benchmarkId,
                // });
                // venue.benchmarks.push(result);
                // setBenchmarks([...venue.benchmarks]);
              },
            });
          }}
        >
          Associate Benchmark
        </Button>
      }
    >
      <Table.Simple
        items={benchmarks}
        headers={[
          { label: "Id", sortMethod: sortByField("id") },
          { label: "Name", sortMethod: sortByField("name") },
          { label: "", sortMethod: null },
        ]}
        itemKey={(item) => {
          return item.id;
        }}
        itemRenderer={(item) => {
          return [
            <Link to={`/benchmarks/${item.id}`}>{item.id}</Link>,
            item.name,
            <HStack css={{ gap: "$2", justifyContent: "end" }}>
              <Button>Remove</Button>
            </HStack>,
          ];
        }}
      />
    </Section>
  );
};

export default VenueDetails;
