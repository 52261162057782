import React from "react";
import { Routes, Route } from "react-router-dom";
import { Box, Redirect, Loader } from "@pp/lib/ui/components";
import { AppLayout, Icons } from "@pp/lib/ui/app";
import api, { useAPI } from "@pp/lib/net/api-service";

import {
  GlobalProvider,
  AccountDatabaseContext,
  TouchpointDefinitionsContext,
} from "./GlobalContexts";
import Overview from "./overview/Overview";
import Accounts from "./accounts/Accounts";
import AccountDetails from "./accounts/AccountDetails";
import Venues from "./venues/Venues";
import VenueDetails from "./venues/VenueDetails";
import Benchmarks from "./benchmarks/Benchmarks";
import StaticScores from "./benchmarks/StaticScores";
import BenchmarkDetails from "./benchmarks/BenchmarkDetails";
import CommentAnalysis from "./comment-analysis/CommentAnalysis";
import CommentAnalysisDetails from "./comment-analysis/CommentAnalysisDetails";
import FieldMappings from "./field-mappings/FieldMappings";
import FieldMappingDetails from "./field-mappings/FieldMappingDetails";
import Users from "./users/Users";
import UserDetails from "./users/UserDetails";

const App = ({ user }) => {
  return (
    <GlobalProvider>
      <AppContent user={user} />
    </GlobalProvider>
  );
};

const AppContent = ({ user }) => {
  const { accountDatabase, setAccountDatabase } = React.useContext(
    AccountDatabaseContext
  );

  const { touchpointDefinitions, setTouchpointDefinitions } = React.useContext(
    TouchpointDefinitionsContext
  );

  React.useEffect(() => {
    const callAPI = async () => {
      const [db, tp] = await Promise.all([
        api.admin.accounts(),
        api.admin.touchpoints(),
      ]);
      setAccountDatabase(db);
      setTouchpointDefinitions(tp);
    };

    callAPI();
  }, []);

  return (
    <Loader
      css={{ width: "100%", height: "100%" }}
      data={accountDatabase && touchpointDefinitions}
      content={() => {
        return (
          <AppLayout
            user={user}
            navItems={[
              { icon: Icons.Donut, label: "Overview", route: "/overview" },
              // { icon: Icons.Library, label: "Accounts", route: "/accounts" },
              // { icon: Icons.Buildings, label: "Venues", route: "/venues" },
              { icon: Icons.Scale, label: "Benchmarks", route: "/benchmarks" },
              // {
              //   icon: Icons.Comment,
              //   label: "Comment Analysis",
              //   route: "/comment-analysis",
              // },
              // {
              //   icon: Icons.Map,
              //   label: "Field Mappings",
              //   route: "/field-mappings",
              // },
              // { icon: Icons.Account, label: "Users", route: "/users" },
            ]}
            mainContent={<MainContent />}
          />
        );
      }}
    />
  );
};

const MainContent = () => {
  return (
    <Box
      css={{
        padding: "$2",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Routes>
        <Route path="/" element={<Redirect to="/overview" />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/accounts/:accountId" element={<AccountDetails />} />
        <Route path="/venues" element={<Venues />} />
        <Route path="/venues/:venueId" element={<VenueDetails />} />
        <Route path="/benchmarks" element={<Benchmarks />} />
        <Route path="/benchmarks/static-scores/:scoreId" element={<StaticScores />} />
        {/* <Route path="/benchmarks/:benchmarkId" element={<BenchmarkDetails />} /> */}
        <Route path="/comment-analysis" element={<CommentAnalysis />} />
        <Route
          path="/comment-analysis/:commentAnalysisId"
          element={<CommentAnalysisDetails />}
        />
        <Route path="/field-mappings" element={<FieldMappings />} />
        <Route
          path="/field-mappings/:fieldMappingId"
          element={<FieldMappingDetails />}
        />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:userId" element={<UserDetails />} />
      </Routes>
    </Box>
  );
};

export default App;
