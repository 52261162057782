import React from "react";
import api from "@pp/lib/net/api-service";
import { Button } from "@pp/lib/ui/components";
import ListPage from "@pp/ui/app/ListPage";

const Users = () => {
  return (
    <ListPage
      title="Users"
      actions={<Button>Create User</Button>}
      path="users"
      apiCall={api.users()}
    />
  );
};

export default Users;