import React, { useEffect } from "react";
import {
  Dialog,
  Button,
  Loader,
  VStack,
  SimpleForm,
  SelectNative,
} from "@pp/lib/ui/components";
import api from "@pp/lib/net/api-service";

const EditVenueDialog = ({ venue, onClose }) => {
  const [loading, setLoading] = React.useState(true);
  const [commentAnalysis, setCommentAnalysis] = React.useState([]);
  const [fieldMappings, setFieldMappings] = React.useState([]);

  useEffect(() => {
    const callAPI = async () => {
      setLoading(true);
      const [commentAnalysis, fieldMappings] = await Promise.all([
        api.commentAnalysisList(),
        api.fieldMappings(),
      ]);
      setLoading(false);
      setFieldMappings(fieldMappings);
      setCommentAnalysis(commentAnalysis);
    };

    callAPI();
  }, []);

  return (
    <Dialog title="Edit Venue" open={true}>
      <Loader
        css={{ p: "$4" }}
        data={!loading}
        content={() => {
          return (
            <DialogContent
              venue={venue}
              commentAnalysis={commentAnalysis}
              fieldMappings={fieldMappings}
              setLoading={setLoading}
              onClose={onClose}
            />
          );
        }}
      />
    </Dialog>
  );
};

const DialogContent = ({
  venue,
  commentAnalysis,
  fieldMappings,
  setLoading,
  onClose,
}) => {
  const {
    getFormValues,
    fields,
  }: {
    getFormValues: () => { id: string; name: string; surveyId: string; status };
    fields: { label: string; control: React.element };
  } = SimpleForm.helper(venue, [
    {
      label: "Venue Name",
      key: "name",
      controlProps: { css: { width: "100%" } },
    },
    {
      label: "Survey Id",
      key: "surveyId",
      controlProps: { css: { width: "100%" } },
    },
    {
      label: "Status",
      key: "status",
      control: SelectNative,
      controlProps: {
        items: [
          { label: "Active", value: "Active" },
          { label: "Disabled", value: "Disabled" },
        ],
        css: { width: "100%" },
      },
    },
    {
      label: "Comment Analysis",
      key: "commentAnalysis",
      control: SelectNative,
      controlProps: {
        items: commentAnalysis.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        css: { width: "100%" },
      },
    },
    {
      label: "Field Mappings",
      key: "fieldMapping",
      control: SelectNative,
      controlProps: {
        items: fieldMappings.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        css: { width: "100%" },
      },
    },
  ]);

  return (
    <VStack css={{ gap: "$4" }}>
      <SimpleForm fields={fields} />
      <Dialog.Buttons>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={async () => {
            setLoading(true);
            const values = getFormValues();
            await api.updateVenue({ id: venue.id, ...values });
            console.log(values);
            for (const key in values) {
              venue[key] = values[key];
            }
            onClose();
          }}
        >
          Update Venue
        </Button>
      </Dialog.Buttons>
    </VStack>
  );
};

export default EditVenueDialog;
