import React from "react";
import api from "@pp/lib/net/api-service";
import { AssociateDialogBase } from "@pp/ui/app/dialogs";

const AssociateBenchmarkDialog = ({
  existingBenchmarks,
  onSubmit,
  onClose,
}) => {
  return (
    <AssociateDialogBase
      title="Associate Benchmark"
      message="Associate a benchmark with this venue."
      existingItems={existingBenchmarks}
      itemsAPICall={api.benchmarks()}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default AssociateBenchmarkDialog;
