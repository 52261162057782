import React from "react";
import api from "@pp/lib/net/api-service";
import { AssociateDialogBase } from "@pp/ui/app/dialogs";

const AssociateUserDialog = ({ existingUsers, onSubmit, onClose }) => {
  return (
    <AssociateDialogBase
      title="Add User"
      message="Add user to this account."
      existingItems={existingUsers}
      itemsAPICall={api.users()}
      buttonLabel={"Add User"}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default AssociateUserDialog;
