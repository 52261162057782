import React from "react";
import api from "@pp/lib/net/api-service";
import { Button } from "@pp/lib/ui/components";
import ListPage from "@pp/ui/app/ListPage";
import { AccountDatabaseContext } from "@pp/ui/app/GlobalContexts";


const Accounts = () => {
  const { accountDatabase } = React.useContext(AccountDatabaseContext);

  return (
    <ListPage
      title="Accounts"
      actions={<Button>Create Account</Button>}
      path="accounts"
      apiCall={api.accounts()}
      additionalFields={[
        {
          key: "venueCount",
          label: "Venue Count",
          itemRender: (item) => item.venues.length,
          sortMethod: (a, b, ascending = true) => {
            if (ascending) {
              return a.venues.length - b.venues.length;
            } else {
              return b.venues.length - a.venues.length;
            }
          },
        },
      ]}
    />
  );
};

export default Accounts;
