import React from "react";
import api from "@pp/lib/net/api-service";
import { Button } from "@pp/lib/ui/components";
import ListPage from "@pp/ui/app/ListPage";

const CommentAnalysis = () => {
  return (
    <ListPage
      title="Comment Analyses"
      actions={<Button>Create Comment Analysis</Button>}
      path="comment-analysis"
      apiCall={api.commentAnalysisList()}
    />
  );
};

export default CommentAnalysis;
