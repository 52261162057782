import React from "react";
import api from "@pp/lib/net/api-service";
import { Button } from "@pp/lib/ui/components";
import ListPage from "@pp/ui/app/ListPage";

const FieldMappings = () => {
  return (
    <ListPage
      title="Field Mappings"
      actions={<Button>Create Field Mapping</Button>}
      path="field-mappings"
      // apiCall={api.fieldMappings()}
    />
  );
};

export default FieldMappings;
